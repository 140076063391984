<template>
    <cms-block v-bind="$props" @block-selected="selectBlock">
        <cms-component-text :component="block.title" v-bind="componentProps"/>
        <cms-component-text :component="block.subtitle" v-bind="componentProps"/>
        <cms-component-text :component="block.description" v-bind="componentProps"/>
        <cms-component-button :component="block.button" v-bind="componentProps"/>
    </cms-block>
</template>

<script>
import CmsBlock from "@/components/editor/rendering/blocks/cms-block.vue";
import CmsComponentButton from "@/components/editor/rendering/components/cms-component-button.vue";
import CmsComponentText from "@/components/editor/rendering/components/cms-component-text.vue";

// cms-block-title #hot-reload-debug
export default {
    name: `cms-block-title`,
    components: {CmsComponentButton, CmsComponentText, CmsBlock},
    extends: CmsBlock
}
</script>

<style lang="scss" scoped>
</style>
